<template>
  <v-container class="pa-0">
    <v-stepper v-model="step" vertical>
      <v-stepper-step :complete="step > 1" step="1">
        Выберите зал
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-3" class="mb-12">
          <v-card-text>
            <v-row dense>
              <v-col
                  v-for="room in rooms"
                  :key="room.id"
                  cols="6"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
              >
                <v-skeleton-loader
                    :loading="loading"
                    v-bind="attrs"
                    type="card"
                    transition="scale-transition"
                >
                  <v-card @click="setRoom(room.id)">
                    <v-img
                        :src="images[room.text_id]"
                        :gradient="getGradient(room)"
                        contain
                    />
                    <v-card-title class="room-card-title pt-0 pb-0 pr-1 pl-1">{{ room.name }}
                    </v-card-title>
                  </v-card>
                </v-skeleton-loader>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-btn :disabled="!currentRoom" color="primary" @click="step = 2">
          Далее
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2">
        Выберите время
      </v-stepper-step>

      <v-stepper-content :class="`${stepOverflowed?'stepper-overflowed':''}`" step="2">
        <v-card color="grey lighten-3" class="mb-12">
          <v-card-text v-if="currentRoom">
            <v-subheader>Выберите дату и время начала</v-subheader>
            <vue-ctk-date-time-picker
                label="Дата и время"
                @is-shown="stepOverflowed=true"
                @is-hidden="stepOverflowed=false"
                v-model="date.start"
                minuteInterval=60
                :noHeader="true"
                format="DD-MM-YYYY HH:mm">
            </vue-ctk-date-time-picker>
            <v-subheader>Выберите продолжительность</v-subheader>
            <v-slider v-model="date.duration"
                      min="3"
                      max="24"
                      :thumb-size="24"
                      thumb-label="always"
                      ticks
                      class="mt-2">
              <template v-slot:append>
                <v-text-field
                    v-model="date.duration"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
        <v-btn :disabled="!date.start" color="primary" @click="step = 3">
          Далее
        </v-btn>
        <v-btn text @click="goBack('day')">
          Назад
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3">
        Выберите количество человек
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card color="grey lighten-3" class="mb-12" height="200px">
          <v-card-text>
            <v-radio-group v-model="guests">
              <v-radio v-for="price in availableGuests" :key="price.id" :label="price.name"
                       :value="price.id">

              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-btn :disabled="!guests" color="primary" @click="step = 4">
          Далее
        </v-btn>
        <v-btn text @click="goBack('guests')">
          Назад
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4">
        Дополнительно
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card
            color="grey lighten-3"
            class="mb-12"
            min-height="200px"
        >
          <v-card-text>
            <v-checkbox :error-messages="alcoDescription" :disabled="!alcoAvailable" v-model="alco" label="Наличие алкоголя"></v-checkbox>
            <v-checkbox v-model="selectedFeatures.garbage"
                        label="У нас будут блестки/конфетти/лепестки/хлопушки/др сыпучие"></v-checkbox>
            <v-divider></v-divider>
            <v-row class="mt-1">
              <v-col cols="12" sm="4"
                     md="4">
                <v-checkbox v-model="selectedFeatures.projector"
                            label="Проектор Benq TH671ST"></v-checkbox>
                <v-checkbox v-model="selectedFeatures.godox"
                            label="Видеосвет Godox sl200ii"></v-checkbox>
                <v-checkbox v-model="selectedFeatures.impulse"
                            label="Импульсный свет Profoto"></v-checkbox>
                <v-checkbox v-model="selectedFeatures.chairs" label="Раскладные стулья до 20 шт."></v-checkbox>
              </v-col>

              <v-col cols="12" sm="4"
                     md="4">
                <v-checkbox v-model="selectedFeatures.dishes20" label="Тарелки и столовые приборы 20шт."></v-checkbox>
                <v-checkbox v-model="selectedFeatures.dishes40" label="Тарелки и столовые приборы 40шт."></v-checkbox>
                <v-checkbox v-model="selectedFeatures.glasses20" label="Бокалы 20шт."></v-checkbox>
                <v-checkbox v-model="selectedFeatures.glasses40" label="Бокалы 40шт."></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-checkbox v-model="selectedFeatures.chairs" label="Раскладные столы до 2 шт."></v-checkbox>
                <v-checkbox v-model="selectedFeatures.sony" label="Колонка Sony GTK-XB72"></v-checkbox>
                <v-checkbox v-model="selectedFeatures.flipchart" label="Флипчарт"></v-checkbox>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="step = 5">
          Далее
        </v-btn>
        <v-btn text @click="goBack('alco')">
          Назад
        </v-btn>
      </v-stepper-content>
      <v-stepper-step :complete="step > 5" step="5">
        Заявка
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-card
            color="grey lighten-3"
            class="mb-12"
            min-height="200px"
        >
          <v-card-text>
            <v-form v-if="!formSended">
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="request.name"
                        label="Имя"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        type="tel"
                        v-model="request.phone"
                        label="Телефон"
                        :rules="[() => phoneIsValid || 'Неправильный формат',
                        () => !!request.phone || 'Это поле обязательно',
                        () => request.phone.length > 10 || 'Слишком короткий номер']"
                        required
                        @keyup="validatePhoneNumber"
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        type="email"
                        v-model="request.email"
                        label="E-mail"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <div v-if="formSended">{{ snackbarText }}</div>
          </v-card-text>
        </v-card>
        <v-btn v-if="!formSended" color="primary" :disabled="!formFilled" @click="sendRequest">
          Отправить
        </v-btn>
        <v-btn v-if="!formSended" text @click="goBack('formSended')">
          Назад
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <total-price-card @update="getDataFromCalc" v-if="guests" :date="date"
                      :guests="availableGuests.find(ag => ag.id === guests)"
                      :room="currentRoom" :alco="alco"
                      :hours="hours" :additionalFeatures="selectedFeatures"></total-price-card>
    <v-snackbar top v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

  </v-container>
</template>

<script>
import TotalPriceCard from "@/components/TotalPriceCard";
import axios from "axios";

export default {
  name: "Calculator",
  components: {TotalPriceCard},
  computed: {
    phoneIsValid() {
      const validationRegex = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
      return this.request.phone.match(validationRegex)
    },
    alcoAvailable() {
      if (this.guests) {
        // if 'undefined' return false
        return this.availableGuests.find(ag => ag.id === this.guests).alco === true
      }
      return false
    },
    alcoDescription() {
      if (!this.alcoAvailable) {
        return 'Мероприятия с алкоголем разрешены только при меньшем количестве человек (для уточнения обратитесь к менеджеру)'
      }
      return ''
    },
    requestText() {
      let additionalFeatures = "";
      Object.entries(this.selectedFeatures).forEach(sf => {
        if (sf[1]) {
          additionalFeatures += '   ' + this.additionalFeatures[sf[0]] + '\n'
        }
      })
      return `
                Имя: ${this.request.name}
                Телефон: ${this.request.phone}
                Почта: ${this.request.email}
                Зал: ${this.currentRoom.name}
                Дата: ${this.date.start}
                Продолжительность: ${this.date.duration}
                Количество людей: ${this.availableGuests.find(ag => ag.id === this.guests).name}
                Стоимость: ${this.calcData.total}
                В том числе уборка: ${this.calcData.cleaning}
                Алкоголь: ${this.alco}
                Допы: \n${additionalFeatures}`;
    },
    currentRoom() {
      return this.room ? this.rooms.find(r => r.id === this.room) : null
    },
    availableGuests() {
      return this.guestOptions.filter(go => this.currentRoom?.availableGuestOptions.includes(go.id));
    },
    formFilled() {
      return this.request.email !== "" && this.request.name !== "" && this.request.phone !== "";
    }
  },
  methods: {
    validatePhoneNumber() {
      const validationRegex = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
      if (this.request.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    getDataFromCalc(data) {
      this.calcData.total = data.total + data.pledge;
      this.calcData.cleaning = data.cleaning;
    },
    sendRequest() {
      axios.post('https://api.telegram.org/bot1868259928:AAH8FugShSEAWbvA3r-BbgNqbI1kjp38w08/sendMessage', {
        chat_id: -570610323,
        text: this.requestText
      }).then(resp => {
        if (resp.status === 200) {
          this.snackbarText = 'Ваша заявка отправлена, в ближайшее время наши администраторы свяжутся с вами.';
          this.snackbar = true;
          this.formSended = true;
        }
      });
    },
    goBack(value) {
      this.step--;
      this[value] = null
    },
    getGradient(room) {
      if (room.id === this.room) {
        return "";
      } else return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";
    },
    setRoom(roomId) {
      this.room = roomId;
    },
    getData() {
      this.loading = true;
      axios.get('https://calcadmin.basephotostudio.ru/api/config').then(resp => {
        if (resp.status === 200) {
          this.guestOptions = resp.data.guestOptions;
          this.rooms = resp.data.rooms
        } else {
          this.snackbarText = 'Произошла ошибка при загрузке данных';
          this.snackbar = true;
        }
        this.loading = false;
      })
    }
  },
  data() {
    return {
      isValidPhoneNumber: true,
      calcData: {
        total: null,
        cleaning: null,
      },
      loading: true,
      attrs: {
        class: 'mb-6',
        elevation: 2,
      },
      formSended: false,
      snackbar: false,
      snackbarText: '',
      images: {
        air: require('../assets/rooms/air.jpg'),
        bang: require('../assets/rooms/bang.jpg'),
        bangcrush: require('../assets/rooms/bangcrush.jpg'),
        allrooms: require('../assets/rooms/allrooms.jpg'),
        bloom: require('../assets/rooms/bloom.jpg'),
        crush: require('../assets/rooms/crush.jpg')
      },
      guestOptions: [],
      rooms: [],
      room: null,
      day: null,
      guests: null,
      step: 1,
      hours: null,
      alco: null,
      datePicker: false,
      date: {
        start: null,
        duration: null,
      },
      stepOverflowed: false,
      additionalFeatures:
          {
            projector: "Проектор Benq TH671ST",
            godox: "Видеосвет Godox sl200ii",
            glasses20: "Бокалы 20 шт.",
            glasses40: "Бокалы 40 шт.",
            dishes20: "Тарелки и столовые приборы 20 шт.",
            dishes40: "Тарелки и столовые приборы 40 шт.",
            chairs: "Раскладные стулья до 20 шт.",
            tables: "Стол раскладной до 2 шт.",
            sony: "Колонка Sony GTK-XB72",
            flipchart: "Флипчарт",
            garbage: "Мусор",
            impulse: "Импульсный свет Profoto"
          },
      selectedFeatures: {
        projector: false,
        godox: false,
        dishes20: false,
        dishes40: false,
        glasses20: false,
        glasses40: false,
        chairs: false,
        tables: false,
        sony: false,
        flipchart: false,
        garbage: false,
        impulse: false,
      },
      request: {
        name: '',
        email: '',
        phone: '',
      }
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>
div.image-radio div[class*=' v-input'] {
  display: none;
}

.stepper-overflowed >>> .v-stepper__wrapper {
  overflow: visible;
}

.room-card-title {
  word-break: break-word;
  font-size: 0.75rem;
  justify-content: center;
}
</style>